import React, {useEffect, useState} from "react";
import {css, Theme, useTheme} from "@emotion/react";
import styled from "@emotion/styled";

import {m, p, pb, pl, prettyScroll} from "@pg-design/helpers-css";
import {Modal} from "@pg-design/modal";
import {SliderGallery, SliderGalleryIcon} from "@pg-design/slider-gallery";
import {Text} from "@pg-design/text";
import {GalleryPicturesGrid} from "@pg-mono/gallery";
import {head} from "@pg-mono/nodash";

import {PropertyPlanButton} from "../../offer/atoms/GalleryHolder";
import {getPropertyPlanImages} from "../helpers/get_property_plan_images";

interface IProps {
    property: {
        number: string;
        plan: string | null;
        external_plan_url: string | null;
        plan_image_pages: {
            image: {
                p_img_560: string;
                p_img_980: string;
                p_img_1500: string;
            };
            page: number;
        }[];
    };
    offerName: string;
    onPlanButtonClick: () => void;
}

export const PropertyPlanModalContent = (props: IProps) => {
    const theme = useTheme();

    const {property} = props;

    const [initialGalleryIndex, setInitialGalleryIndex] = useState(0);
    const [galleryCounter, setGalleryCounter] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        setGalleryCounter(initialGalleryIndex);
    }, [initialGalleryIndex]);

    if (property.plan_image_pages.length <= 1) {
        const image = head(property.plan_image_pages)?.image;

        if (!image) {
            return null;
        }

        return (
            <PropertyPlanModalHolder>
                <div css={planImageHolder}>
                    <picture css={planPicture}>
                        <source media="(min-width: 1024px)" srcSet={image?.p_img_1500} />
                        <img css={[planImage]} src={image?.p_img_980} alt={`rzut nieruchomości ${property.number} z inwestycji ${props.offerName}`} />
                    </picture>
                </div>
            </PropertyPlanModalHolder>
        );
    }

    const propertyPlanImages = getPropertyPlanImages(property.plan_image_pages);

    const onClose = () => {
        setInitialGalleryIndex(0);
        setIsModalOpen(false);
    };

    return (
        <PropertyPlanModalHolder>
            <GalleryPicturesGrid
                getImageAlt={(index) => `rzut ${index + 1} w nieruchomości ${props.property.number} z inwestycji ${props.offerName}`}
                images={propertyPlanImages}
                onImageClick={(index: number) => {
                    setIsModalOpen(true);
                    setInitialGalleryIndex(index);
                }}
            />

            <Modal fullScreen isOpen={isModalOpen} onModalClose={onClose} modalTheme="dark">
                <Modal.CloseButton position="right" />
                <Modal.Content>
                    <div css={gallerySliderHolder}>
                        <SliderGallery
                            initialSlide={initialGalleryIndex}
                            onSlideChange={(current: number, next: number) => {
                                setGalleryCounter(next);
                            }}
                            arrows={{
                                left: <SliderGalleryIcon position="left" wrapperColor={theme.colors.primary} />,
                                right: <SliderGalleryIcon position="right" wrapperColor={theme.colors.primary} />
                            }}
                            slides={propertyPlanImages.map((image, index) => {
                                return (
                                    <picture key={index}>
                                        <source media="(min-width: 750px)" srcSet={image?.srcLg} />
                                        <source media="(min-width: 480px)" srcSet={image?.srcMd} />
                                        <img src={image?.srcSm} alt={property.number} />
                                    </picture>
                                );
                            })}
                        />

                        <div css={counterHolder}>
                            <Text variant="info_txt_1" color="#fff">
                                Rzut: {galleryCounter + 1} z {property.plan_image_pages.length}
                            </Text>
                        </div>
                    </div>
                </Modal.Content>
            </Modal>

            <div css={propertyPlanButtonHolder}>
                {property && property.plan && <PropertyPlanButton onPlanButtonClick={props.onPlanButtonClick} propertyPlan={property.plan} />}
            </div>
        </PropertyPlanModalHolder>
    );
};

const PropertyPlanModalHolder = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    @media (min-width: ${(props) => props.theme.breakpoints.md}) {
        ${prettyScroll("#686975", "#838595")};
        ${pl(3)};
        ${pb(3)};
    }
`;

const planImageHolder = css`
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
`;

const planPicture = css`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const planImage = css`
    object-fit: contain;
    display: block;
    max-width: 100%;
    max-height: 100%;
`;

const propertyPlanButtonHolder = (theme: Theme) => css`
    ${m(1, 0)};

    @media (min-width: ${theme.breakpoints.md}) {
        display: none;
    }
`;

const gallerySliderHolder = css`
    position: relative;
    width: 100%;
    height: 100%;
`;

const counterHolder = css`
    position: absolute;
    bottom: 5rem;
    left: 50%;
    transform: translate(-50%, 0%);
    ${p(1, 2)};
    background-color: rgba(67, 68, 77, 0.7);
`;
